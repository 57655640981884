"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.twoFaClient = exports.twoFaMethods = exports.twoFaPath = void 0;
exports.twoFaPath = 'two-fa';
exports.twoFaMethods = ['find', 'get', 'create', 'patch', 'remove'];
const twoFaClient = (client) => {
    const connection = client.get('connection');
    client.use(exports.twoFaPath, connection.service(exports.twoFaPath), {
        methods: exports.twoFaMethods
    });
};
exports.twoFaClient = twoFaClient;
