"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filesClient = exports.filesMethods = exports.filesPath = void 0;
exports.filesPath = 'files';
exports.filesMethods = ['find', 'get', 'create', 'patch', 'remove'];
const filesClient = (client) => {
    const connection = client.get('connection');
    client.use(exports.filesPath, connection.service(exports.filesPath), {
        methods: exports.filesMethods
    });
};
exports.filesClient = filesClient;
