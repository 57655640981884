"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.docxAnalysisClient = exports.docxAnalysisMethods = exports.docxAnalysisPath = void 0;
exports.docxAnalysisPath = 'docx-analysis';
exports.docxAnalysisMethods = ['find', 'get', 'create', 'patch', 'remove'];
const docxAnalysisClient = (client) => {
    const connection = client.get('connection');
    client.use(exports.docxAnalysisPath, connection.service(exports.docxAnalysisPath), {
        methods: exports.docxAnalysisMethods
    });
};
exports.docxAnalysisClient = docxAnalysisClient;
