"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.emailTutorialClient = exports.emailTutorialMethods = exports.emailTutorialPath = void 0;
exports.emailTutorialPath = 'email-tutorial';
exports.emailTutorialMethods = ['find', 'get', 'create', 'patch', 'remove'];
const emailTutorialClient = (client) => {
    const connection = client.get('connection');
    client.use(exports.emailTutorialPath, connection.service(exports.emailTutorialPath), {
        methods: exports.emailTutorialMethods
    });
};
exports.emailTutorialClient = emailTutorialClient;
