"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.configClient = exports.configMethods = exports.configPath = void 0;
exports.configPath = 'config';
exports.configMethods = ['find', 'get', 'create', 'patch', 'remove'];
const configClient = (client) => {
    const connection = client.get('connection');
    client.use(exports.configPath, connection.service(exports.configPath), {
        methods: exports.configMethods
    });
};
exports.configClient = configClient;
