"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.emailClient = exports.emailMethods = exports.emailPath = void 0;
exports.emailPath = 'emails';
exports.emailMethods = ['find', 'get', 'create', 'patch', 'remove'];
const emailClient = (client) => {
    const connection = client.get('connection');
    client.use(exports.emailPath, connection.service(exports.emailPath), {
        methods: exports.emailMethods
    });
};
exports.emailClient = emailClient;
