"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.passwordResetClient = exports.passwordResetMethods = exports.passwordResetPath = void 0;
exports.passwordResetPath = 'password-reset';
exports.passwordResetMethods = ['create', 'get', 'remove'];
const passwordResetClient = (client) => {
    const connection = client.get('connection');
    client.use(exports.passwordResetPath, connection.service(exports.passwordResetPath), {
        methods: exports.passwordResetMethods
    });
};
exports.passwordResetClient = passwordResetClient;
