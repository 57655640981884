"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.exportsClient = exports.exportsMethods = exports.exportsPath = void 0;
exports.exportsPath = 'exports';
exports.exportsMethods = ['find', 'get', 'create', 'patch', 'remove'];
const exportsClient = (client) => {
    const connection = client.get('connection');
    client.use(exports.exportsPath, connection.service(exports.exportsPath), {
        methods: exports.exportsMethods
    });
};
exports.exportsClient = exportsClient;
