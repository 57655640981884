"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.projectsClient = exports.projectsMethods = exports.projectsPath = void 0;
exports.projectsPath = 'projects';
exports.projectsMethods = ['find', 'get', 'create', 'patch', 'remove'];
const projectsClient = (client) => {
    const connection = client.get('connection');
    client.use(exports.projectsPath, connection.service(exports.projectsPath), {
        methods: exports.projectsMethods
    });
};
exports.projectsClient = projectsClient;
