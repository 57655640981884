"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.hooks = exports.middleware = void 0;
const base_js_1 = require("./base.js");
const hooks_js_1 = require("./hooks.js");
__exportStar(require("./hooks.js"), exports);
__exportStar(require("./compose.js"), exports);
__exportStar(require("./base.js"), exports);
__exportStar(require("./regular.js"), exports);
/**
 * Initializes a hook settings object with the given middleware.
 * @param mw The list of middleware
 * @param options Middleware options (params, default, props)
 */
function middleware(mw, options) {
    const manager = new base_js_1.HookManager().middleware(mw);
    if (options) {
        if (options.params) {
            manager.params(...options.params);
        }
        if (options.defaults) {
            manager.defaults(options.defaults);
        }
        if (options.props) {
            manager.props(options.props);
        }
    }
    return manager;
}
exports.middleware = middleware;
// Fallthrough to actual implementation
function hooks(...args) {
    const [target, _hooks] = args;
    if (typeof target === 'function' &&
        (_hooks instanceof base_js_1.HookManager || Array.isArray(_hooks) ||
            args.length === 1)) {
        return (0, hooks_js_1.functionHooks)(target, _hooks);
    }
    if (args.length === 2) {
        return (0, hooks_js_1.objectHooks)(target, _hooks);
    }
    return (0, hooks_js_1.hookDecorator)(target);
}
exports.hooks = hooks;
