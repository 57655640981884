"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.aiShareholdersResolutionClient = exports.aiShareholdersResolutionMethods = exports.aiShareholdersResolutionPath = void 0;
exports.aiShareholdersResolutionPath = 'ai-shareholders-resolution';
exports.aiShareholdersResolutionMethods = ['find', 'get', 'create', 'patch', 'remove'];
const aiShareholdersResolutionClient = (client) => {
    const connection = client.get('connection');
    client.use(exports.aiShareholdersResolutionPath, connection.service(exports.aiShareholdersResolutionPath), {
        methods: exports.aiShareholdersResolutionMethods
    });
};
exports.aiShareholdersResolutionClient = aiShareholdersResolutionClient;
