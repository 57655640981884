"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.imanageRequestClient = exports.imanageRequestMethods = exports.imanageRequestPath = void 0;
exports.imanageRequestPath = 'imanage-request';
exports.imanageRequestMethods = ['find', 'get', 'create', 'patch', 'remove'];
const imanageRequestClient = (client) => {
    const connection = client.get('connection');
    client.use(exports.imanageRequestPath, connection.service(exports.imanageRequestPath), {
        methods: exports.imanageRequestMethods
    });
};
exports.imanageRequestClient = imanageRequestClient;
