"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.collect = exports.fromErrorHook = exports.fromAfterHook = exports.fromBeforeHook = exports.runHooks = exports.runHook = void 0;
const compose_js_1 = require("./compose.js");
const runHook = (hook, context, type) => {
    const typeBefore = context.type;
    if (type)
        context.type = type;
    return Promise.resolve(hook.call(context.self, context)).then((res) => {
        if (type)
            context.type = typeBefore;
        if (res && res !== context) {
            Object.assign(context, res);
        }
    });
};
exports.runHook = runHook;
const runHooks = (hooks) => (context) => hooks.reduce((promise, hook) => promise.then(() => (0, exports.runHook)(hook, context)), Promise.resolve(context));
exports.runHooks = runHooks;
function fromBeforeHook(hook) {
    return (context, next) => {
        return (0, exports.runHook)(hook, context, 'before').then(next);
    };
}
exports.fromBeforeHook = fromBeforeHook;
function fromAfterHook(hook) {
    return (context, next) => {
        return next().then(() => (0, exports.runHook)(hook, context, 'after'));
    };
}
exports.fromAfterHook = fromAfterHook;
function fromErrorHook(hook) {
    return (context, next) => {
        return next().catch((error) => {
            if (context.error !== error || context.result !== undefined) {
                context.original = { ...context };
                context.error = error;
                delete context.result;
            }
            return (0, exports.runHook)(hook, context, 'error')
                .then(() => {
                if (context.result === undefined && context.error !== undefined) {
                    throw context.error;
                }
            })
                .catch((error) => {
                context.error = error;
                throw context.error;
            });
        });
    };
}
exports.fromErrorHook = fromErrorHook;
function collect({ before = [], after = [], error = [], }) {
    const beforeHooks = before.map(fromBeforeHook);
    const afterHooks = [...after].reverse().map(fromAfterHook);
    const errorHooks = error.length ? [fromErrorHook((0, exports.runHooks)(error))] : [];
    return (0, compose_js_1.compose)([...errorHooks, ...beforeHooks, ...afterHooks]);
}
exports.collect = collect;
