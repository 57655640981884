"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.projectsToUsersClient = exports.projectsToUsersMethods = exports.projectsToUsersPath = void 0;
exports.projectsToUsersPath = 'projects-to-users';
exports.projectsToUsersMethods = ['find', 'get', 'create', 'patch', 'remove'];
const projectsToUsersClient = (client) => {
    const connection = client.get('connection');
    client.use(exports.projectsToUsersPath, connection.service(exports.projectsToUsersPath), {
        methods: exports.projectsToUsersMethods
    });
};
exports.projectsToUsersClient = projectsToUsersClient;
