"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.projectAliasClient = exports.projectAliasMethods = exports.projectAliasPath = void 0;
exports.projectAliasPath = 'project-alias';
exports.projectAliasMethods = ['find', 'get', 'create', 'patch', 'remove'];
const projectAliasClient = (client) => {
    const connection = client.get('connection');
    client.use(exports.projectAliasPath, connection.service(exports.projectAliasPath), {
        methods: exports.projectAliasMethods
    });
};
exports.projectAliasClient = projectAliasClient;
