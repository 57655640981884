"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.workstreamClient = exports.workstreamMethods = exports.workstreamPath = void 0;
exports.workstreamPath = 'workstreams';
exports.workstreamMethods = ['find', 'get', 'create', 'patch', 'remove'];
const workstreamClient = (client) => {
    const connection = client.get('connection');
    client.use(exports.workstreamPath, connection.service(exports.workstreamPath), {
        methods: exports.workstreamMethods
    });
};
exports.workstreamClient = workstreamClient;
