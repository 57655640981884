"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sortWorkstreamHierarchical = exports.createTree = exports.combineWorkstreamsWithProperties = exports.sortWorkstreamByIndexAscending = exports.sortWorkstreamByIndexDescending = void 0;
const sortByReference_1 = require("./sortByReference");
const sortWorkstreamByIndexDescending = (a, b) => a.index > b.index ? -1 : 1;
exports.sortWorkstreamByIndexDescending = sortWorkstreamByIndexDescending;
const sortWorkstreamByIndexAscending = (a, b) => a.index < b.index ? -1 : 1;
exports.sortWorkstreamByIndexAscending = sortWorkstreamByIndexAscending;
function combineWorkstreamsWithProperties(wss, properties) {
    const workstreamsWithProperties = wss.map(ws => {
        const propertiesForWorkstream = properties.filter(p => p.workstreamId == ws.id);
        // Create object with property names as keys
        const propertiesObject = propertiesForWorkstream.reduce((acc, p) => {
            acc[p.key] = p.value;
            return acc;
        }, {});
        return {
            ...ws,
            properties: propertiesObject || null
        };
    });
    return workstreamsWithProperties;
}
exports.combineWorkstreamsWithProperties = combineWorkstreamsWithProperties;
function createTree(wss) {
    const createTree = (parentId, depth, parent) => {
        return wss.filter(ws => ws.parentId == parentId).sort(exports.sortWorkstreamByIndexAscending).map(ws => {
            return Object.assign(ws, {
                depth,
                parent,
                children: createTree(ws.id, depth + 1, ws)
            });
        });
    };
    const tree = createTree(null, 0);
    return tree;
}
exports.createTree = createTree;
function sortWorkstreamHierarchical(wss) {
    const sortingFunction = (0, sortByReference_1.sortByPrefixAndReference)(wss);
    // Create hierarchy from flat list recursively
    const tree = createTree(wss);
    // Flatten hierachy recursively. Not obvious: in createTree we sort by index ascending, which is 
    // what we want for depth = 0, eg. root level workstreams. We want to sort by reference in all other cases,
    // which works because we use the "sortingFunction" created earlier when flattening the tree.
    const flatten = (ws) => {
        return [ws, ...(ws.children?.sort(sortingFunction).flatMap(flatten) || [])];
    };
    const sortedWorkstreams = tree.flatMap(ws => flatten(ws));
    return sortedWorkstreams;
}
exports.sortWorkstreamHierarchical = sortWorkstreamHierarchical;
