"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pdfTableClient = exports.pdfTableMethods = exports.pdfTablePath = void 0;
exports.pdfTablePath = 'pdf-table';
exports.pdfTableMethods = ['find', 'get', 'create', 'patch', 'remove'];
const pdfTableClient = (client) => {
    const connection = client.get('connection');
    client.use(exports.pdfTablePath, connection.service(exports.pdfTablePath), {
        methods: exports.pdfTableMethods
    });
};
exports.pdfTableClient = pdfTableClient;
