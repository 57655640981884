import { Injectable } from '@angular/core';
import { ClientApplication, createClient } from 'annex-tracker-backend'
import authentication from '@feathersjs/authentication-client'
import { Manager } from 'socket.io-client'
import socketio from '@feathersjs/socketio-client'
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';

export interface ServiceEvent<T> {
  type: 'created' | 'patched' | 'updated' | 'removed'
  entity: T
}

@Injectable({
  providedIn: 'root'
})
export class BackendService {

  reconnected$ = new Subject<void>()

  get users() { return this.client.service("users") }
  get files() { return this.client.service("files") }
  get fileParts() { return this.client.service("file-parts") }
  get projects() { return this.client.service("projects") }
  get projectsToUsers() { return this.client.service("projects-to-users") }
  get workstream() { return this.client.service("workstreams") }
  get docxAnalysis() { return this.client.service("docx-analysis") }
  get workstreamProperty() { return this.client.service("properties") }
  get pdfTable() { return this.client.service("pdf-table") }
  get email() { return this.client.service("emails") }
  get emailContact() { return this.client.service("contacts") }
  get emailsToEmailContacts() { return this.client.service("contacts-to-emails") }
  get emailsToWorkstreams() { return this.client.service("workstreams-to-emails") }
  get sendMail() { return this.client.service("email-tutorial") }
  get convertedFile() { return this.client.service("converted-files") }
  get exportedTrackers() { return this.client.service("exports") }
  get comparedFiles() { return this.client.service("compared-files") }
  get passwordReset() { return this.client.service("password-reset") }
  get avInfo() { return this.client.service("av-info") }
  get columns() { return this.client.service("columns") }
  get twoFa() { return this.client.service("two-fa") }
  get config() { return this.client.service("config") }
  get alias() { return this.client.service("project-alias") }
  get coverPages() { return this.client.service("cover-pages") }
  get coverPageToProjects() { return this.client.service("cover-page-to-project") }
  get shareHolderResolution() { return this.client.service("ai-shareholders-resolution") }
  get aiGuessTitles() { return this.client.service("ai-guess-titles") }
  get iManageCredentials() { return this.client.service("imanage-credentials")}

  public client: ClientApplication

  constructor() {

    const manager = new Manager(environment.api)

    const ioConnection = manager.socket("/")
    const connection = socketio(ioConnection)

    manager.on('reconnect', () => {
      console.log("reconnect")
      this.reconnected$.next()
    })

    this.client = createClient(connection as any)
    this.client.configure(authentication() as any)

  }

}
