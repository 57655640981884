import { Paginated, Params, Service } from '@feathersjs/feathers';
import deepmerge from 'deepmerge';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { BaseEntity } from '../model/base-entity';


export interface ServiceEvent<T> {
    type: 'created' | 'patched' | 'updated' | 'removed'
    entity: T
}

const types = ['created', 'patched', 'updated', 'removed'] as ('created' | 'patched' | 'updated' | 'removed')[]

export function makeObservable(service: any): Observable<ServiceEvent<any>> {

    return new Observable(subscribe => {
        const listeners = types.map(type =>
            (entity: any) => {
                subscribe.next({ type, entity })
            })
        types.forEach((type, index) => {
            service.on(type, listeners[index])
        })
        return () => {
            types.forEach((type, index) => {
                service.off(type, listeners[index])
            })
        }
    })
}

export function makeObservableWithTransform(service: any, transform: (x: any) => Promise<any>): Observable<ServiceEvent<any>> {
    return new Observable(subscribe => {
        const listeners = types.map(type =>
            (entity: any) => {
                subscribe.next({ type, entity })
            })

        types.forEach(async type => {
            service.on(type, async (entity: any) => {
                subscribe.next({ type, entity: await transform(entity) })
            })
        })

        return () => {
            types.forEach((type, index) => {
                service.off(type, listeners[index])
            })
        }
    })
}

export function observableQuery(service: any, transform: (x: any) => Promise<any>, queries: Observable<Params | undefined>, completeOnFinish = false): Observable<any[]> {

    return queries.pipe(switchMap(q => {

        return new Observable<any[]>(subscribe => {

            if (q == null) {
                subscribe.next([])
                return
            }

            let cancelled = false

            async function getAll() {
                let total = 0
                let list = [] as any[];
                do {

                    const thisQuery = deepmerge(q!, {
                        query: {
                            $skip: list.length,
                            $limit: 100,
                        }
                    })

                    try {
                        
                        let res = await service.find(thisQuery) as Paginated<any>
                        list.push(... await Promise.all(res.data.map(async d => await transform(d))))
                        total = res.total
                    } catch (e: any) {
                        console.log(`Observable query errored for ${service['path']}`)
                        console.log(e, service)
                        cancelled = true
                    }

                    // console.log(`getting more of ${service['path']}`, list.length)

                } while (list.length < total && !cancelled)
                subscribe.next(list);
                if (completeOnFinish) {
                    subscribe.complete();
                }
            }
            getAll();

            return () => {
                // Stop the while loop in the next iteration
                cancelled = true
            }
        }).pipe(shareReplay(1))
    }))

}

export function makeObservableList<T extends BaseEntity>(
    service: any,
    transform: (x: T) => Promise<T>,
    queries: Observable<Params | undefined>,
    filters: Observable<(x: ServiceEvent<T>) => boolean>,
    debug = false,
    idField = 'id'): Observable<T[]> {

    return combineLatest([
        observableQuery(service, transform, queries),
        filters
    ]).pipe(
        switchMap(([initialList, filters]) => {
            
            if (debug) { console.log("makeObservableList " + service['path']) }
            return makeObservableWithTransform(service, transform).pipe(filter(filters), map(serviceEvent => {

                if (debug) { console.log("'" + service['path'] + "' " + serviceEvent.type, serviceEvent) }

                switch (serviceEvent.type) {
                    case 'created':
                        if (initialList.findIndex((d: any) => d[idField] == (serviceEvent.entity as any)[idField]) > -1) {
                            console.warn(`duplicate id on <${service['path']}>`)
                            console.log('existing', initialList.find((d: any) => d[idField] == (serviceEvent.entity as any)[idField]))
                            console.log('new', serviceEvent.entity)
                        } else {
                            initialList.push(serviceEvent.entity)
                        }
                        break;
                    case 'patched': {
                        const idx = initialList.findIndex((d: any) => d[idField] == (serviceEvent.entity as any)[idField])
                        if (idx > -1)
                            initialList[idx] = serviceEvent.entity
                    }
                        break;
                    case 'updated': {
                        const idx = initialList.findIndex((d: any) => d[idField] == (serviceEvent.entity as any)[idField])
                        initialList[idx] = serviceEvent.entity
                    }
                        break;
                    case 'removed':
                        initialList = initialList.filter((d: any) => !(d[idField] == (serviceEvent.entity as any)[idField]))
                        break;
                }
                return [...initialList];
            }),
                startWith(initialList))
        }),
        shareReplay(1)
    )

}


