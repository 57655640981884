"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.propertyClient = exports.propertyMethods = exports.propertyPath = void 0;
exports.propertyPath = 'properties';
exports.propertyMethods = ['find', 'get', 'create', 'patch', 'remove'];
const propertyClient = (client) => {
    const connection = client.get('connection');
    client.use(exports.propertyPath, connection.service(exports.propertyPath), {
        methods: exports.propertyMethods
    });
};
exports.propertyClient = propertyClient;
