import { Injectable } from '@angular/core';
import { Id } from '@feathersjs/feathers';
import { BackendService } from './backend.service';
import { ConvertedFile, File } from 'annex-tracker-backend';
import { FilesParams } from 'annex-tracker-backend/lib/services/files/files.class';
import { filter, firstValueFrom, fromEvent, map, share, tap } from 'rxjs';

// Time in ms after which the cache entry is removed because the url in the cache 
// also has an expiration date
const CACHE_ITEM_VALIDITY = 10 * 60 * 1000

const cache_awsIdToUrl = new Map<number, string>();
const cache_fileToPdfUrl = new Map<number, string>();

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  constructor(private backend: BackendService) { }

  convertedFilesListener = fromEvent(this.backend.convertedFile, 'patched').pipe(map(x => x as ConvertedFile), share())

  private async requestConversion(fileId: number, type: string) {

    const res = await this.backend.convertedFile.find({
      query: {
        fileId: fileId,
        type: type,
        $limit: 1
      }
    })

    // Either no conversion has been requested yet or the conversion is not done yet
    if (res.total == 0 || res.data[0].status != 'done') {

      // If there was no conversion requested yet, create a new conversion, but don't wait 
      // for it to be created because we'll hear about it from the listener below
      if (res.total == 0) {
        this.backend.convertedFile.create({
          fileId: fileId,
          type: type
        }).then(createdConversion => console.log("created conversion", createdConversion))
      }

      // In any case, wait for the conversion to be done
      const convertedFile = await firstValueFrom(this.convertedFilesListener.pipe(
        tap(x => console.log("converted file", x)),
        filter(convertedFile =>
          convertedFile.fileId == fileId &&
          convertedFile.type == type &&
          convertedFile.status == 'done')
      ))
      return this.backend.convertedFile.get(convertedFile.id)

    } else {
      return this.backend.convertedFile.get(res.data[0].id)
    }

  }

  async fileToPdfUrl(file?: File) {

    console.log("get pdf for file", file?.filename)

    if (file == null) {
      return null
    }

    if (cache_fileToPdfUrl.has(file.id)) {
      console.log("cache hit for file", file.id)
      return cache_fileToPdfUrl.get(file.id)!
    }

    if (file.type != "application/pdf") {
      console.log("requesting conversion")
      const convertedFileUrl = await this.requestConversion(file.id, "pdf")
      console.log("conversion done", convertedFileUrl)
      cache_fileToPdfUrl.set(file.id, convertedFileUrl.presignedUrl!)

    } else {

      const fileResponse = await this.backend.files.get(file.id)
      cache_fileToPdfUrl.set(file.id, fileResponse.presignedUrl!)

    }

    setTimeout(() => {
      cache_fileToPdfUrl.delete(file.id)
    }, CACHE_ITEM_VALIDITY)

    const url = cache_fileToPdfUrl.get(file.id)!
    console.log(url)

    return url
  }

  async fileToTextUrl(fileId: number) {

    const convertedFileUrl = await this.requestConversion(fileId, "txt")
    return convertedFileUrl.presignedUrl
  }

  async awsIdToUrl(value?: Id, ...args: string[]): Promise<string | null> {

    if (value == null) {
      return null
    }
    const file = await this.backend.files.get(value)

    if (file.presignedUrl) {
      return file.presignedUrl
    }

    return null

  }
}
