"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.contactToEmailClient = exports.contactToEmailMethods = exports.contactToEmailPath = void 0;
exports.contactToEmailPath = 'contacts-to-emails';
exports.contactToEmailMethods = ['find', 'get', 'create', 'patch', 'remove'];
const contactToEmailClient = (client) => {
    const connection = client.get('connection');
    client.use(exports.contactToEmailPath, connection.service(exports.contactToEmailPath), {
        methods: exports.contactToEmailMethods
    });
};
exports.contactToEmailClient = contactToEmailClient;
