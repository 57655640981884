"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.workstreamToEmailClient = exports.workstreamToEmailMethods = exports.workstreamToEmailPath = void 0;
exports.workstreamToEmailPath = 'workstreams-to-emails';
exports.workstreamToEmailMethods = ['find', 'get', 'create', 'patch', 'remove'];
const workstreamToEmailClient = (client) => {
    const connection = client.get('connection');
    client.use(exports.workstreamToEmailPath, connection.service(exports.workstreamToEmailPath), {
        methods: exports.workstreamToEmailMethods
    });
};
exports.workstreamToEmailClient = workstreamToEmailClient;
