import { Injectable } from '@angular/core';
import { Id, NullableId } from '@feathersjs/feathers';
import { BehaviorSubject, combineLatest, filter, firstValueFrom, from, map, ReplaySubject, Subject, switchMap, timeout } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { BackendService } from './backend.service';
import { makeObservableList, ServiceEvent } from './helpers/observable-service';
import { sortDescending } from './model/base-entity';
import { Projects, ProjectsToUsers } from 'annex-tracker-backend';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {

  public projectIds = new ReplaySubject<number | null>(1)
  public project$ = new ReplaySubject<Projects | undefined>(1)

  private projects = new ReplaySubject<Projects[]>(1)
  private projectsToUsers = new ReplaySubject<ProjectsToUsers[]>(1)
  private projectsWithMembership = new ReplaySubject<Projects[]>(1);

  activeProjects = new ReplaySubject<Projects[]>(1)
  newPitchDecks = new ReplaySubject<Projects[]>(1)
  interestingProjects = new ReplaySubject<Projects[]>(1)
  dismissedPitchDecks = new ReplaySubject<Projects[]>(1)

  projectIdQuery = new ReplaySubject<any>(1)
  projectIdQueryWithoutDate = new ReplaySubject<{ query: { projectId: NullableId}} | undefined>(1)
  projectIdFilter = new ReplaySubject<(_: ServiceEvent<any>) => boolean>(1)

  currentProject?: Projects

  constructor(private backend: BackendService, authentication: AuthenticationService) {

    this.backend.users.on("was-added-to-project", data => {
      authentication.reEmitUser()
    })

    this.backend.reconnected$.subscribe(async () => {
      // Re-emit project id when reconnected and get project to be added to the project 
      // channel serverside 
      const currentId = await firstValueFrom(this.projectIds)
      if (currentId != null) {
        this.loadProject(currentId)
      }
    })

    this.projectIds.subscribe(projectId => {
      this.getProject(projectId)
    })

    const mapState = (state: string) => map((projects: Projects[]) => projects.filter(project => project.projects_to_users?.state == state).sort(sortDescending))

    this.projectsWithMembership.pipe(mapState('inbox')).subscribe(this.newPitchDecks)
    this.projectsWithMembership.pipe(mapState('interesting')).subscribe(this.interestingProjects)
    this.projectsWithMembership.pipe(mapState('dismissed')).subscribe(this.dismissedPitchDecks)
    this.projectsWithMembership.pipe(mapState('active')).subscribe(this.activeProjects)

    const userQuery = authentication.user$.pipe(map(user => {
      if (user == null) {
        return undefined
      } else {
        return { query: { userId: user!.id } }
      }
    }))

    const emptyQuery = authentication.user$.pipe(map(user => {
      if (user == null) {
        return undefined
      } else {
        return {}
      }
    }))

    this.projectIds.pipe(map(projectId => {
      if (projectId == null) {
        return undefined
      } else {
        return { query: { projectId: projectId, $sort: { createdAt: -1 } } }
      }
    })).subscribe(this.projectIdQuery)

    this.projectIds.pipe(map(projectId => {
      if (projectId == null) {
        return undefined
      } else {
        return { query: { projectId: projectId } }
      }
    })).subscribe(this.projectIdQueryWithoutDate)

    this.projectIds.pipe(map(projectId => {
      if (projectId == null) {
        return (_: ServiceEvent<{ projectId: NullableId }>) => false
      } else {
        return (ws: ServiceEvent<{ projectId: NullableId }>) => ws.entity.projectId == projectId
      }
    })).subscribe(this.projectIdFilter)

    makeObservableList(backend.projects, x => Promise.resolve(x), emptyQuery, from([() => true])).subscribe(this.projects as any)
    makeObservableList(backend.projectsToUsers, x => Promise.resolve(x), userQuery, from([() => true])).subscribe(this.projectsToUsers as any)

    combineLatest([
      this.projects,
      this.projectsToUsers
    ]).pipe(map(([projects, projectsToUsers]) => {
      return projects.map(project => {
        project.projects_to_users = projectsToUsers.find(x => x.projectId == project.id)
        return project
      })
    }),
      map(projects => projects.filter(p => !p.archived))
    ).subscribe(this.projectsWithMembership)

    this.projectIds.pipe(
      switchMap(projectId => 
        this.projects.pipe(
          map(projects => projects.find(p => p.id == projectId))
        )
      )
    ).subscribe(this.project$);

  }

  async getCurrentProjectId() {
    return firstValueFrom(this.projectIds.pipe(filter(x => x != null), timeout(1000)))
  }

  private async getProject(projectId: NullableId) {
    if (projectId == null) {
      this.currentProject = undefined
      return undefined
    }
    
    this.currentProject = await this.backend.projects.get(projectId)
    return this.currentProject
  }

  loadProject(projectId: number | null) {
    this.projectIds.next(projectId)
  }

  async markInteresting(project: Projects) {
    await this.backend.projectsToUsers.patch(project.projects_to_users!.id, {
      state: 'interesting'
    })
  }

  async markDismissed(project: Projects) {
    await this.backend.projectsToUsers.patch(project.projects_to_users!.id, {
      state: 'dismissed'
    })
  }

  async markDeleted(project: Projects) {
    await this.backend.projectsToUsers.patch(project.projects_to_users!.id, {
      state: 'deleted'
    })
  }

  async markInbox(project: Projects) {
    await this.backend.projectsToUsers.patch(project.projects_to_users!.id, {
      state: 'inbox'
    })
  }

}
