import { Injectable } from '@angular/core';
import { MatomoTracker } from 'ngx-matomo-client';
import { BehaviorSubject, filter, firstValueFrom, take, timeout } from 'rxjs';
import { BackendService } from './backend.service';
import { User, UserData } from 'annex-tracker-backend';
import { authenticator } from 'otplib'

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public user$: BehaviorSubject<User | null>
  public isAuthenticated = false
  
  constructor(private backend: BackendService, private matomo: MatomoTracker) {

    this.user$ = new BehaviorSubject<User | null>(null);
    this.tryLogin()

    this.backend.client.on('login', (authentication: any) => {
      this.user$.next(authentication.user)
      this.isAuthenticated = true
      this.matomo.setUserId(authentication.user.email)
    })

    this.backend.client.on('logout', () => {
      this.user$.next(null)
      this.isAuthenticated = false
    })

    this.backend.users.on('patched', user => {
      this.user$.next(user)
    })

  }

  public hasStoredAuthenticationToken() {
    return localStorage.getItem("feathers-jwt") != null
  }

  public async reAuthenticate() {
    await this.backend.client.reAuthenticate(true)
  }

  public async tryLogin() {

    try {
      await this.backend.client.reAuthenticate()
      console.log("User: Auto-authentication successful.")
    } catch (e: any) {
      console.log("User: Auto-authentication failed.", e.message)
      await this.backend.client.authentication.reset()
    }

  }

  async authenticate(user: Partial<User>, twoFaCode?: string) {
    await this.backend.client.logout()
    return await this.backend.client.authenticate({
      strategy: "local",
      email: user.email?.toLowerCase(),
      password: user.password,
      twoFaCode
    })
  }

  async awaitableUser() {

    if (!this.hasStoredAuthenticationToken()) {
      throw new Error("Not authenticated")
    }

    const user = await firstValueFrom(this.user$.pipe(filter(x => x != null), timeout(1000)))

    if (user == null) {
      throw new Error("Not authenticated")
    }
    return user
  }

  async signup(user: UserData) {
    return await this.backend.client.service("users").create(user)
  }

  async logout() {
    return await this.backend.client.logout()
  }

  async reEmitUser() {
    const currentUser = await this.awaitableUser()
    this.user$.next(currentUser)
  }

  async createTwoFa() {

    return await this.backend.twoFa.create({
      type: 'totp'
    })

  }

  async enableTwoFa(code: string) {
    const user = await this.awaitableUser()
    return this.backend.twoFa.patch(user.id, {
      enabled: true,
    }, {
      query: { currentTwoFaCode: code }
    })
  }

  async getCurrentTwoFa() {
    try {
      const user = await this.awaitableUser()
      const twoFa = await this.backend.twoFa.get(user.id)
      return twoFa
    } catch (e) {
      return undefined
    }
  }

  async disableTwoFa(code?: string) {
    const user = await this.awaitableUser()
    await this.backend.twoFa.remove(user.id, {
      query: {
        currentTwoFaCode: code
      }
    })
  }
  
}
