"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.avInfoClient = exports.avInfoMethods = exports.avInfoPath = void 0;
exports.avInfoPath = 'av-info';
exports.avInfoMethods = ['find'];
const avInfoClient = (client) => {
    const connection = client.get('connection');
    client.use(exports.avInfoPath, connection.service(exports.avInfoPath), {
        methods: exports.avInfoMethods
    });
};
exports.avInfoClient = avInfoClient;
