"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertedFileClient = exports.convertedFileMethods = exports.convertedFilePath = void 0;
exports.convertedFilePath = 'converted-files';
exports.convertedFileMethods = ['find', 'get', 'create', 'patch', 'remove'];
const convertedFileClient = (client) => {
    const connection = client.get('connection');
    client.use(exports.convertedFilePath, connection.service(exports.convertedFilePath), {
        methods: exports.convertedFileMethods
    });
};
exports.convertedFileClient = convertedFileClient;
