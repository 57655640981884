"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.imanageCredentialsClient = exports.imanageCredentialsMethods = exports.imanageCredentialsPath = void 0;
exports.imanageCredentialsPath = 'imanage-credentials';
exports.imanageCredentialsMethods = ['find', 'get', 'create', 'patch', 'remove'];
const imanageCredentialsClient = (client) => {
    const connection = client.get('connection');
    client.use(exports.imanageCredentialsPath, connection.service(exports.imanageCredentialsPath), {
        methods: exports.imanageCredentialsMethods
    });
};
exports.imanageCredentialsClient = imanageCredentialsClient;
