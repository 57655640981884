"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.coverPagesClient = exports.coverPagesMethods = exports.coverPagesPath = void 0;
exports.coverPagesPath = 'cover-pages';
exports.coverPagesMethods = ['find', 'get', 'create', 'patch', 'remove'];
const coverPagesClient = (client) => {
    const connection = client.get('connection');
    client.use(exports.coverPagesPath, connection.service(exports.coverPagesPath), {
        methods: exports.coverPagesMethods
    });
};
exports.coverPagesClient = coverPagesClient;
