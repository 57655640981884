import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, from, lastValueFrom, map, Observable } from 'rxjs';
import { BackendService } from './backend.service';
import { makeObservableList, observableQuery, ServiceEvent } from './helpers/observable-service';
import { ProjectsService } from './projects.service';
import { Property, PropertyData, Workstream } from 'annex-tracker-backend';

@Injectable({
  providedIn: 'root'
})
export class WorkstreamPropertyService {

  properties: Observable<Property[]>

  time = new BehaviorSubject<number | undefined>(1678795292052)

  constructor(
    private backend: BackendService,
    private project: ProjectsService,
  ) {

    const projectIdQuery = project.projectIds.pipe(map(projectId => {
      if (projectId == null) {
        return undefined
      } else {
        return { query: { projectId: projectId, $sort: { createdAt: -1 }, deleted: false } }
      }
    }))

    const projectIdFilter = project.projectIds.pipe(map(projectId => {
      if (projectId == null) {
        return (_: ServiceEvent<any>) => false
      } else {
        return (ws: ServiceEvent<any>) => ws.entity.projectId == projectId
      }
    }))

    this.properties = makeObservableList(backend.workstreamProperty, async x => x, projectIdQuery, projectIdFilter)
      .pipe(
        map(properties => properties.filter(property => !property["deleted"])),
      )

  }

  async setTo(key: string, value: any, workstream: Workstream) {
    return this.setToWsId(key, value, workstream.id)
  }

  async setToWsId(key: string, value: any, workstreamId: number) {
    const projectId = await this.project.getCurrentProjectId()

    if (projectId == null) {
      throw new Error("Can set property without active project")
    }

    console.log("set property", key, value, workstreamId)

    const property = await this.backend.workstreamProperty.create({
      projectId: projectId,
      workstreamId: workstreamId,
      key,
      value
    })

    return property
  }

  async setProperty(property: PropertyData | PropertyData[]) {

    const projectId = await this.project.getCurrentProjectId()

    if (projectId == null) {
      throw new Error("Can set property without active project")
    }

    if (Array.isArray(property)) {
      const properties = property.map(property => Object.assign({}, property, { projectId: projectId }))
      return this.backend.workstreamProperty.create(properties)
    } else {
      return this.backend.workstreamProperty.create(Object.assign({}, property, { projectId: projectId }))
    }

  }

  async getAllPropertyValues(key: string) {

    const projectId = await this.project.getCurrentProjectId()

    const values = await lastValueFrom(
      observableQuery(this.backend.workstreamProperty, async x => x, from([{
        query: {
          projectId,
          key,
          $select: ['value'],

        }
      }]), true))

    return [... new Set<string>(values.map(value => value.value))]
  }

  async getAllPropertyValuesForWorkstream(key: string, workstreamId: number) {

    const projectId = await this.project.getCurrentProjectId()

    const values = await lastValueFrom(
      observableQuery(this.backend.workstreamProperty, async x => x, from([{
        query: {
          projectId,
          key,
          workstreamId,
          // $select: ['value'],

        }
      }]), true))

    return values
  }

  async deleteProperty(property: Property) {
    return this.backend.workstreamProperty.remove(property.id)
  }

}
