import { Injectable } from '@angular/core';
import { map, firstValueFrom, ReplaySubject, combineLatest, Observable, shareReplay, debounceTime, lastValueFrom } from 'rxjs';
import { BackendService } from './backend.service';
import { makeObservableList } from './helpers/observable-service';
import { ProjectsService } from './projects.service';
import { NullableId } from '@feathersjs/feathers';
import { FilesService } from './files.service';
import { WorkstreamPropertyService } from './workstream-property.service';
import { Workstream, File as FileRecord } from 'annex-tracker-backend';

import { HWorkstream, combineWorkstreamsWithProperties, sortWorkstreamHierarchical } from 'annex-tracker-backend/lib/helpers/sortWorkstreamHierarchical'
import { MatomoTracker } from 'ngx-matomo-client'

@Injectable({
  providedIn: 'root',
})
export class WorkstreamsService {

  workstreams$: Observable<HWorkstream[]>

  private rawWorkstreams = new ReplaySubject<Workstream[]>(1)

  files = new ReplaySubject<FileRecord[]>(1)
  currentWorkstream: Observable<HWorkstream | null>
  currentWorkstreamId = new ReplaySubject<number | null>(1)

  constructor(
    private backend: BackendService,
    private project: ProjectsService,
    private filesService: FilesService,
    private workstreamProperties: WorkstreamPropertyService,
    private matomo: MatomoTracker) {

    makeObservableList(backend.workstream, x => Promise.resolve(x), project.projectIdQuery, project.projectIdFilter).subscribe(this.rawWorkstreams)
    makeObservableList(backend.files, x => Promise.resolve(x), project.projectIdQuery, project.projectIdFilter).subscribe(this.files)

    this.workstreams$ = combineLatest([
      this.rawWorkstreams,
      this.workstreamProperties.properties,
    ]).pipe(
      map(([wss, properties]) => combineWorkstreamsWithProperties(wss, properties)),
      map(sortWorkstreamHierarchical),
      shareReplay(1)

    )

    this.currentWorkstream = combineLatest([
      this.workstreams$,
      this.currentWorkstreamId,
    ]).pipe(
      debounceTime(100),
      map(([wss, currentWorkstreamId]) => wss.find(ws => ws.id == currentWorkstreamId) || null),
      shareReplay(1)
    )

    this.workstreams$.pipe(debounceTime(200)).subscribe((wss) => {

      wss.filter(ws => ws.parentId == null).forEach(ws => {
        this.repairWorkstreamReferencesSorting(ws.id)
      })

      wss.filter(ws => ws.parentId == null).forEach((ws, index) => {
        if (ws.index != index) {
          this.backend.workstream.patch(ws.id, { index })
        }
      })

    })


  }

  async createWorkstream(parentId: number | null = null, newIndex?: number) {

    const wss = await firstValueFrom(this.rawWorkstreams)
    const index = newIndex ?? wss.length
    const projectId = await firstValueFrom(this.project.projectIds) as number
    //this.matomo.trackEvent("Workstream", "Create", workstream.name)

    if (projectId == null) {
      throw "Project context needed"
    }

    return this.backend.workstream.create({
      index: index,
      parentId: parentId,
      projectId: projectId
    })

  }

  async repairWorkstreamIndices(workstreams: Workstream[]) {

    if (workstreams.some((ws, index) => ws.index != index)) {
      await Promise.all(
        workstreams.map((ws, index) => this.backend.workstream.patch(ws.id, { index }))
      )
      workstreams.forEach((ws, index) => ws.index = index)
    }

    return workstreams
  }

  async repairWorkstreamReferencesSorting(parentId: NullableId) {
    const currentWorkstreams = await firstValueFrom(this.workstreams$)

    const sortedWorkstreams = sortWorkstreamHierarchical(currentWorkstreams)

    await Promise.all(sortedWorkstreams.filter(ws => ws.parentId == parentId).map((ws, index) => {
      if (ws.index != index) {
        return this.backend.workstream.patch(ws.id, { index })
      } else {
        return Promise.resolve()
      }
    }))
  }

  async deleteWorkstream(workstream: Workstream) {

    //this.matomo.trackEvent("Workstream", "Remove", workstream.name)
    return this.backend.workstream.remove(workstream.id)

  }


  async openInTab(file: FileRecord) {

    try {

      const workstreams = await firstValueFrom(this.rawWorkstreams)
      const ws = workstreams.find(ws => ws.id == file.workstreamId)
      //this.matomo.trackEvent("Workstream", "Open in tab", `${ws?.name} / ${file.filename}`)

    } catch {
      return
    }

    const a = document.createElement("a")

    let url = await this.filesService.fileToPdfUrl(file)

    a.href = url!
    a.target = '_blank'
    a.click()
  }

  async download(file: FileRecord) {

    try {

      const workstreams = await firstValueFrom(this.rawWorkstreams)
      const ws = workstreams.find(ws => ws.id == file.workstreamId)
      //this.matomo.trackEvent("Workstream", "Download", `${ws?.name} / ${file.filename}`)

    } catch { }

    const a = document.createElement("a")
    document.body.appendChild(a)

    let url = await this.filesService.awsIdToUrl(file.id, 'attachment')

    a.href = url!
    a.target = '_blank'
    a.click()

    document.body.removeChild(a)
  }

}
