"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.coverPageToProjectClient = exports.coverPageToProjectMethods = exports.coverPageToProjectPath = void 0;
exports.coverPageToProjectPath = 'cover-page-to-project';
exports.coverPageToProjectMethods = ['find', 'get', 'create', 'patch', 'remove'];
const coverPageToProjectClient = (client) => {
    const connection = client.get('connection');
    client.use(exports.coverPageToProjectPath, connection.service(exports.coverPageToProjectPath), {
        methods: exports.coverPageToProjectMethods
    });
};
exports.coverPageToProjectClient = coverPageToProjectClient;
