"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.columnsClient = exports.columnsMethods = exports.columnsPath = void 0;
exports.columnsPath = 'columns';
exports.columnsMethods = ['find', 'get', 'create', 'patch', 'remove'];
const columnsClient = (client) => {
    const connection = client.get('connection');
    client.use(exports.columnsPath, connection.service(exports.columnsPath), {
        methods: exports.columnsMethods
    });
};
exports.columnsClient = columnsClient;
