"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.comparedFilesClient = exports.comparedFilesMethods = exports.comparedFilesPath = void 0;
exports.comparedFilesPath = 'compared-files';
exports.comparedFilesMethods = ['find', 'get', 'create', 'patch', 'remove'];
const comparedFilesClient = (client) => {
    const connection = client.get('connection');
    client.use(exports.comparedFilesPath, connection.service(exports.comparedFilesPath), {
        methods: exports.comparedFilesMethods
    });
};
exports.comparedFilesClient = comparedFilesClient;
