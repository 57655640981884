"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.contactClient = exports.contactMethods = exports.contactPath = void 0;
exports.contactPath = 'contacts';
exports.contactMethods = ['find', 'get', 'create', 'patch', 'remove'];
const contactClient = (client) => {
    const connection = client.get('connection');
    client.use(exports.contactPath, connection.service(exports.contactPath), {
        methods: exports.contactMethods
    });
};
exports.contactClient = contactClient;
