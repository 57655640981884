"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filePartClient = exports.filePartMethods = exports.filePartPath = void 0;
exports.filePartPath = 'file-parts';
exports.filePartMethods = ['find', 'get', 'create', 'patch', 'remove'];
const filePartClient = (client) => {
    const connection = client.get('connection');
    client.use(exports.filePartPath, connection.service(exports.filePartPath), {
        methods: exports.filePartMethods
    });
};
exports.filePartClient = filePartClient;
