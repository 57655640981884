"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.aiGuessTitlesClient = exports.aiGuessTitlesMethods = exports.aiGuessTitlesPath = void 0;
exports.aiGuessTitlesPath = 'ai-guess-titles';
exports.aiGuessTitlesMethods = ['create'];
const aiGuessTitlesClient = (client) => {
    const connection = client.get('connection');
    client.use(exports.aiGuessTitlesPath, connection.service(exports.aiGuessTitlesPath), {
        methods: exports.aiGuessTitlesMethods
    });
};
exports.aiGuessTitlesClient = aiGuessTitlesClient;
